export default function isValidContactNumber(phone, country) {
    console.log(phone,country)
    // return phone.match(
    //     /^(?:0|94|\+94)?(?:(11|21|23|24|25|26|27|31|32|33|34|35|36|37|38|41|45|47|51|52|54|55|57|63|65|66|67|81|912)(0|2|3|4|5|7|9)|7(0|1|2|4|5|6|7|8)\d)\d{6}$/
    // ) ? true : false;
    // const newZealandRegex = /^(\+?64|0)[28]\d{8,9}$/;
    // const australiaRegex = /^(\+?61|0)4\d{8}$/;
    // const usaRegex = /^(\+?1)?[2-9]\d{2}[2-9](?!11)\d{6}$/;
    // const fijiRegex = /^(\+?679)?\s?\d{3}\s?\d{4}$/;

    // Check if the phone number matches either of the regular expressions
    // if (country === "New Zealand" && newZealandRegex.test(phone)) {
    //     return true;
    // } else if (country === "Australia" && australiaRegex.test(phone)) {
    //     return true;
    // } else if (country === "USA" && usaRegex.test(phone)) {
    //     return true;
    // } else if (country === "Fiji" && fijiRegex.test(phone)) {
    //     return true;
    // } else if (!country){
    //     return newZealandRegex.test(phone) || australiaRegex.test(phone) || usaRegex.test(phone) || fijiRegex.test(phone);
    // }else{
    //     return false;
    // }
    return true;
}
